<template>
<v-slide-y-transition>
  <div class="alert_popup" v-if="alertVisibile">
    <v-alert
      class="elevation-5"
      v-model="alertVisibile"
      dismissible
      :color="alertCfg.color || 'info'"
      :icon="alertCfg.icon || 'mdi-information-outline'"
      dark
      border="left"
      close-text="Close Alert"
    >
      {{alertCfg.msg || 'No message'}}
    </v-alert>
  </div>
</v-slide-y-transition>
</template>

<script>
  export default {
    computed:{
      alertVisibile:{
        get(){
          return this.$store.getters['uiMod/getAlertVisibility'];
        },
        set(v){
          this.$store.dispatch('uiMod/setAlertVisibility', v)
        }
      },
      alertCfg(){
        return this.$store.getters['uiMod/getAlertCfg'];
      }
    }
  }
  
</script>

<style lang="scss" scoped>
  .alert_popup{
    position: absolute;
    top:10px;
    left: 10px;
    right: 10px;
    z-index: 1001;
  }
</style>